import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
  json: any;
  uuid: any;
};

/** columns and relationships of "contact_message" */
export type Contact_Message = {
  __typename?: 'contact_message';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contact_message" */
export type Contact_Message_Aggregate = {
  __typename?: 'contact_message_aggregate';
  aggregate?: Maybe<Contact_Message_Aggregate_Fields>;
  nodes: Array<Contact_Message>;
};

/** aggregate fields of "contact_message" */
export type Contact_Message_Aggregate_Fields = {
  __typename?: 'contact_message_aggregate_fields';
  avg?: Maybe<Contact_Message_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contact_Message_Max_Fields>;
  min?: Maybe<Contact_Message_Min_Fields>;
  stddev?: Maybe<Contact_Message_Stddev_Fields>;
  stddev_pop?: Maybe<Contact_Message_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contact_Message_Stddev_Samp_Fields>;
  sum?: Maybe<Contact_Message_Sum_Fields>;
  var_pop?: Maybe<Contact_Message_Var_Pop_Fields>;
  var_samp?: Maybe<Contact_Message_Var_Samp_Fields>;
  variance?: Maybe<Contact_Message_Variance_Fields>;
};


/** aggregate fields of "contact_message" */
export type Contact_Message_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Message_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contact_message" */
export type Contact_Message_Aggregate_Order_By = {
  avg?: Maybe<Contact_Message_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contact_Message_Max_Order_By>;
  min?: Maybe<Contact_Message_Min_Order_By>;
  stddev?: Maybe<Contact_Message_Stddev_Order_By>;
  stddev_pop?: Maybe<Contact_Message_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contact_Message_Stddev_Samp_Order_By>;
  sum?: Maybe<Contact_Message_Sum_Order_By>;
  var_pop?: Maybe<Contact_Message_Var_Pop_Order_By>;
  var_samp?: Maybe<Contact_Message_Var_Samp_Order_By>;
  variance?: Maybe<Contact_Message_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "contact_message" */
export type Contact_Message_Arr_Rel_Insert_Input = {
  data: Array<Contact_Message_Insert_Input>;
  on_conflict?: Maybe<Contact_Message_On_Conflict>;
};

/** aggregate avg on columns */
export type Contact_Message_Avg_Fields = {
  __typename?: 'contact_message_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "contact_message" */
export type Contact_Message_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "contact_message". All fields are combined with a logical 'AND'. */
export type Contact_Message_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contact_Message_Bool_Exp>>>;
  _not?: Maybe<Contact_Message_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contact_Message_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "contact_message" */
export enum Contact_Message_Constraint {
  /** unique or primary key constraint */
  ContactMessageEmailNameKey = 'contact_message_email_name_key',
  /** unique or primary key constraint */
  ContactMessagePkey = 'contact_message_pkey'
}

/** input type for incrementing integer column in table "contact_message" */
export type Contact_Message_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "contact_message" */
export type Contact_Message_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contact_Message_Max_Fields = {
  __typename?: 'contact_message_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "contact_message" */
export type Contact_Message_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contact_Message_Min_Fields = {
  __typename?: 'contact_message_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "contact_message" */
export type Contact_Message_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** response of any mutation on the table "contact_message" */
export type Contact_Message_Mutation_Response = {
  __typename?: 'contact_message_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contact_Message>;
};

/** input type for inserting object relation for remote table "contact_message" */
export type Contact_Message_Obj_Rel_Insert_Input = {
  data: Contact_Message_Insert_Input;
  on_conflict?: Maybe<Contact_Message_On_Conflict>;
};

/** on conflict condition type for table "contact_message" */
export type Contact_Message_On_Conflict = {
  constraint: Contact_Message_Constraint;
  update_columns: Array<Contact_Message_Update_Column>;
  where?: Maybe<Contact_Message_Bool_Exp>;
};

/** ordering options when selecting data from "contact_message" */
export type Contact_Message_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** primary key columns input for table: "contact_message" */
export type Contact_Message_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "contact_message" */
export enum Contact_Message_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "contact_message" */
export type Contact_Message_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contact_Message_Stddev_Fields = {
  __typename?: 'contact_message_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "contact_message" */
export type Contact_Message_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contact_Message_Stddev_Pop_Fields = {
  __typename?: 'contact_message_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "contact_message" */
export type Contact_Message_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contact_Message_Stddev_Samp_Fields = {
  __typename?: 'contact_message_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "contact_message" */
export type Contact_Message_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contact_Message_Sum_Fields = {
  __typename?: 'contact_message_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "contact_message" */
export type Contact_Message_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "contact_message" */
export enum Contact_Message_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Text = 'text'
}

/** aggregate var_pop on columns */
export type Contact_Message_Var_Pop_Fields = {
  __typename?: 'contact_message_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "contact_message" */
export type Contact_Message_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contact_Message_Var_Samp_Fields = {
  __typename?: 'contact_message_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "contact_message" */
export type Contact_Message_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contact_Message_Variance_Fields = {
  __typename?: 'contact_message_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "contact_message" */
export type Contact_Message_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

export type ContactFormInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  recaptcha: Scalars['String'];
};

export type ContactFormInsertResult = {
  __typename?: 'ContactFormInsertResult';
  message?: Maybe<Scalars['String']>;
};

export type EmailOutput = {
  __typename?: 'emailOutput';
  success: Scalars['Boolean'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "contact_message" */
  delete_contact_message?: Maybe<Contact_Message_Mutation_Response>;
  /** delete single row from the table: "contact_message" */
  delete_contact_message_by_pk?: Maybe<Contact_Message>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** perform the action: "insertContactMessage" */
  insertContactMessage?: Maybe<ContactFormInsertResult>;
  /** insert data into the table: "contact_message" */
  insert_contact_message?: Maybe<Contact_Message_Mutation_Response>;
  /** insert a single row into the table: "contact_message" */
  insert_contact_message_one?: Maybe<Contact_Message>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** perform the action: "sendEmail" */
  sendEmail?: Maybe<EmailOutput>;
  /** update data of the table: "contact_message" */
  update_contact_message?: Maybe<Contact_Message_Mutation_Response>;
  /** update single row of the table: "contact_message" */
  update_contact_message_by_pk?: Maybe<Contact_Message>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_Contact_MessageArgs = {
  where: Contact_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contact_Message_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsertContactMessageArgs = {
  contactFormInput: ContactFormInput;
};


/** mutation root */
export type Mutation_RootInsert_Contact_MessageArgs = {
  objects: Array<Contact_Message_Insert_Input>;
  on_conflict?: Maybe<Contact_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_Message_OneArgs = {
  object: Contact_Message_Insert_Input;
  on_conflict?: Maybe<Contact_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootSendEmailArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Contact_MessageArgs = {
  _inc?: Maybe<Contact_Message_Inc_Input>;
  _set?: Maybe<Contact_Message_Set_Input>;
  where: Contact_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contact_Message_By_PkArgs = {
  _inc?: Maybe<Contact_Message_Inc_Input>;
  _set?: Maybe<Contact_Message_Set_Input>;
  pk_columns: Contact_Message_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "contact_message" */
  contact_message: Array<Contact_Message>;
  /** fetch aggregated fields from the table: "contact_message" */
  contact_message_aggregate: Contact_Message_Aggregate;
  /** fetch data from the table: "contact_message" using primary key columns */
  contact_message_by_pk?: Maybe<Contact_Message>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootContact_MessageArgs = {
  distinct_on?: Maybe<Array<Contact_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Message_Order_By>>;
  where?: Maybe<Contact_Message_Bool_Exp>;
};


/** query root */
export type Query_RootContact_Message_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Message_Order_By>>;
  where?: Maybe<Contact_Message_Bool_Exp>;
};


/** query root */
export type Query_RootContact_Message_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

export type SampleInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  accessToken: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "contact_message" */
  contact_message: Array<Contact_Message>;
  /** fetch aggregated fields from the table: "contact_message" */
  contact_message_aggregate: Contact_Message_Aggregate;
  /** fetch data from the table: "contact_message" using primary key columns */
  contact_message_by_pk?: Maybe<Contact_Message>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootContact_MessageArgs = {
  distinct_on?: Maybe<Array<Contact_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Message_Order_By>>;
  where?: Maybe<Contact_Message_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContact_Message_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Message_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contact_Message_Order_By>>;
  where?: Maybe<Contact_Message_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContact_Message_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['String'];
  last_seen?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name'
}


export type InsertContactMessageActionMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  recaptcha: Scalars['String'];
}>;


export type InsertContactMessageActionMutation = (
  { __typename?: 'mutation_root' }
  & { insertContactMessage?: Maybe<(
    { __typename?: 'ContactFormInsertResult' }
    & Pick<ContactFormInsertResult, 'message'>
  )> }
);

export type GetContactMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactMessagesQuery = (
  { __typename?: 'query_root' }
  & { contact_message: Array<(
    { __typename?: 'contact_message' }
    & Pick<Contact_Message, 'name' | 'email'>
  )> }
);


export const InsertContactMessageActionDocument = gql`
    mutation InsertContactMessageAction($email: String!, $name: String!, $recaptcha: String!) {
  insertContactMessage(contactFormInput: {email: $email, name: $name, recaptcha: $recaptcha}) {
    message
  }
}
    `;
export type InsertContactMessageActionMutationFn = ApolloReactCommon.MutationFunction<InsertContactMessageActionMutation, InsertContactMessageActionMutationVariables>;

/**
 * __useInsertContactMessageActionMutation__
 *
 * To run a mutation, you first call `useInsertContactMessageActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactMessageActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactMessageActionMutation, { data, loading, error }] = useInsertContactMessageActionMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      recaptcha: // value for 'recaptcha'
 *   },
 * });
 */
export function useInsertContactMessageActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertContactMessageActionMutation, InsertContactMessageActionMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertContactMessageActionMutation, InsertContactMessageActionMutationVariables>(InsertContactMessageActionDocument, baseOptions);
      }
export type InsertContactMessageActionMutationHookResult = ReturnType<typeof useInsertContactMessageActionMutation>;
export type InsertContactMessageActionMutationResult = ApolloReactCommon.MutationResult<InsertContactMessageActionMutation>;
export type InsertContactMessageActionMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertContactMessageActionMutation, InsertContactMessageActionMutationVariables>;
export const GetContactMessagesDocument = gql`
    query GetContactMessages {
  contact_message {
    name
    email
  }
}
    `;

/**
 * __useGetContactMessagesQuery__
 *
 * To run a query within a React component, call `useGetContactMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactMessagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactMessagesQuery, GetContactMessagesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContactMessagesQuery, GetContactMessagesQueryVariables>(GetContactMessagesDocument, baseOptions);
      }
export function useGetContactMessagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactMessagesQuery, GetContactMessagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContactMessagesQuery, GetContactMessagesQueryVariables>(GetContactMessagesDocument, baseOptions);
        }
export type GetContactMessagesQueryHookResult = ReturnType<typeof useGetContactMessagesQuery>;
export type GetContactMessagesLazyQueryHookResult = ReturnType<typeof useGetContactMessagesLazyQuery>;
export type GetContactMessagesQueryResult = ApolloReactCommon.QueryResult<GetContactMessagesQuery, GetContactMessagesQueryVariables>;