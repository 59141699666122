import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import certificateIllustration from "../placeholders/pictures/certificate.svg";
import newIdeasIllustration from "../placeholders/pictures/new_ideas.svg";
import shieldIcon from "../placeholders/icons/shield.svg";
import cloudToolsIcon from "../placeholders/icons/cloud-tools.svg";
import cartIcon from "../placeholders/icons/cart.svg";
import serverIcon from "../placeholders/icons/server.svg";
import kundeIcon from "../placeholders/pictures/kunde.png";
import realwebIcon from "../placeholders/logos/realweb.svg";
import gitscapeIcon from "../placeholders/logos/gitscape.svg";
import kholeIcon from "../placeholders/logos/k-hole.svg";
import pluckyIcon from "../placeholders/logos/plucky.svg";
import firstsightIcon from "../placeholders/logos/1stsight.svg";

import Cta from "./../components/cta";

function IndexPage() {
  return (
    <>
      <Layout>
        <SEO
          keywords={[
            `Bestes Webhosting`,
            `Wordpress Hosting`,
            `E-Mail-Hosting`,
            `Domain-Hosting`,
            `XYZ-Domain`,
            `Domainübertragung`,
            `Ecommerce Hosting`,
            `Cloud Hosting`,
            `Günstiges SSL`,
            `Brand Design`,
            `Content Creator Website`,
            `Corporate Design`,
            `Corporate Identity`,
            `Eigene Website`,
            `CMS Hosting`,
            `Private Cloud Hosting`,
          ]}
          title="Home"
        />
        <Cta />
        <section className="py-12 pb-12 px-4">
          <div className="flex flex-wrap -mx-8 -mb-8">
            <div className="md:w-1/2 px-8 mb-8">
              <div className="flex -mx-4">
                <img className="w-1/4 md:w-1/6 px-4" src={shieldIcon} alt="" />
                <div className="w-3/4 px-4">
                  <h3 className="text-xl my-2 font-heading">Sicheres Webhosting</h3>
                  <p className="text-sm text-gray-500 leading-relaxed">Regelmäßige Datensicherungen und Updates werden durch uns durchgeführt. Unsere Infrastruktur setzt auf modernste Sicherheitsstandards im Netz.</p>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 px-8 mb-8">
              <div className="flex -mx-4">
                <img className="w-1/4 md:w-1/6 px-4" src={cloudToolsIcon} alt="" />
                <div className="w-3/4 px-4">
                  <h3 className="text-xl my-2 font-heading">Wartungs-Service</h3>
                  <p className="text-sm text-gray-500 leading-relaxed">Aktuelle Sicherheitsstandards im Netz sind pflicht. Wir kümmern uns um alle Updates die Ihre Seite benötigt, um auch in Zukunft sicher zu bleiben. </p>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 px-8 mb-8">
              <div className="flex -mx-4">
                <img className="w-1/4 md:w-1/6 px-4" src={cartIcon} alt="" />
                <div className="w-3/4 px-4">
                  <h3 className="text-xl my-2 font-heading">Reichweite erhöhen</h3>
                  <p className="text-sm text-gray-500 leading-relaxed">Wir unterstützen Sie darin im Web sichtbar zu werden und kümmern uns um Ihren Google Maps Eintrag</p>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 px-8 mb-8">
              <div className="flex -mx-4">
                <img className="w-1/4 md:w-1/6 px-4" src={serverIcon} alt="" />
                <div className="w-3/4 px-4">
                  <h3 className="text-xl my-2 font-heading">Individuell</h3>
                  <p className="text-sm text-gray-500 leading-relaxed">Wir sorgen dafür, dass Sie die Website erhalten, die zu Ihnen passt und Ihren Vorstellungen entspricht. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="produkt" className="py-12 px-4">
          <div className="flex flex-wrap items-center text-center lg:text-left -mx-2">
            <div className="lg:w-1/2 px-2 lg:pr-10 mt-10 lg:mt-0 order-1 lg:order-none">
              <h2 className="text-5xl mb-6 leading-tight font-heading">Professionelle   Website &amp; Rundum-Service</h2>
              <p className="mb-8 text-gray-500 leading-relaxed">Homepage-Baukästen sind nicht unser Ding. Wir bauen Deine Homepage individuell für Dich und kümmern uns um Deinen Online-Erfolg.</p>
            </div>
            <div className="lg:w-1/2 px-2"><img src={certificateIllustration} alt="" /></div>
          </div>
        </section>
        <section className="py-12 px-4" id="mehr-erfahren">
          <div className="flex flex-wrap items-center text-center lg:text-left -mx-2">
            <div className="lg:w-1/2 px-2"><img src={newIdeasIllustration} alt="" /></div>
            <div className="lg:w-1/2 px-2 lg:pl-16 mt-10 lg:mt-0">
              <h2 className="text-4xl px-4 mb-4 leading-tight font-heading">Wie Funktionierts?</h2>
              <div className="p-4 mb-4 rounded shadow-lg">
                <h3 className="text-2xl mb-2 font-heading">1. Kontaktieren Sie uns</h3>
                <p className="text-gray-500 leading-relaxed">Wir bearbeiten Ihre Anfrage und setzen uns mit Ihnen persönlich in Verbindung um Ihre Website nach Ihren Wünschen gestalten zu können.</p>
              </div>
              <div className="p-4 mb-4">
                <h3 className="text-2xl mb-2 font-heading">2. Schicken Sie uns Texte und Bilder</h3>
                <p className="text-gray-500 leading-relaxed">Wir begleiten Sie persönlich um Ihre Wunschtexte und Bilder für Sie einpflegen zu können.</p>
              </div>
              <div className="p-4 mb-4">
                <h3 className="text-2xl mb-2 font-heading">3. Wir stellen Ihre Website Online!</h3>
                <p className="text-gray-500 leading-relaxed">Ihre neue Website ist live und kann besucht werden. Änderungswünsche und Anpassungen sind jederzeit möglich.</p>
              </div>
            </div>
          </div>
        </section>
        {
          /* 
          <section className="py-12 px-4 text-center">
            <div className="max-w-4xl mx-auto">
              <div className="flex flex-wrap items-center mb-8 rounded shadow-md">
                <div className="md:w-1/2"><img src={kundeIcon} alt="" /></div>
                <div className="md:w-1/2 p-8 text-left">
                  <p className="text-xl mb-4">Endlich muss ich mich nicht mehr selbst um meine Website kümmern. Super! </p>
                  <p className="font-semibold">Yulia Lisovaya</p>
                  <p className="text-sm text-gray-500">Geschäftsführerin sattlereibodensee.de</p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-center -mx-4 -mb-8">
                <div className="w-1/3 md:w-1/5 px-4 mb-8"><img src={realwebIcon} alt="" /></div>
                <div className="w-1/3 md:w-1/5 px-4 mb-8"><img src={gitscapeIcon} alt="" /></div>
                <div className="w-1/3 md:w-1/5 px-4 mb-8"><img src={kholeIcon} alt="" /></div>
                <div className="w-1/3 md:w-1/5 px-4 mb-8"><img src={pluckyIcon} alt="" /></div>
                <div className="w-1/3 md:w-1/5 px-4 mb-8"><img src={firstsightIcon} alt="" /></div>
              </div>
            </div>
          </section>
        */
        }
      </Layout>
    </>
  );
}

export default IndexPage;
