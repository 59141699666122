import React, { useState, useEffect } from "react";
import cogoToast from 'cogo-toast';
import { useInsertContactMessageActionMutation } from "../generated/graphql"
import starIcon from "../placeholders/icons/star.svg";
import { useKeycloak } from '@react-keycloak/web';
function cta() {
  const { keycloak, initialized } = useKeycloak();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [insertContactMessage, { data, loading, error }] = useInsertContactMessageActionMutation({
    name: name,
    email: email,
    recaptcha: recaptcha,
    context: {
     headers: {
    //    Authorization:
    //      keycloak && keycloak.token ? `Bearer ${keycloak.token}` : {},
      },
    }
  },
    {
      onCompleted: ({ data }) => {
        cogoToast.success("Ihre Anfrage wurde erfasst. Danke!");
      }
    }
  );

  const handleLoaded = _ => {
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LePp7MZAAAAABijZYD10oQqin6LC8ERlJ_CD0xF", { action: "homepage" })
        .then(token => {
          setRecaptcha(token);
        })
    })
  }

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render=6LePp7MZAAAAABijZYD10oQqin6LC8ERlJ_CD0xF";
    script.setAttribute('defer','');
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, [])

  const sendContactMessage = function (e) {
    e.preventDefault();
    insertContactMessage({ variables: { name, email, recaptcha } }).then(
      result => {
        cogoToast.success('Erfolgreich versendet');
        // success callback
      },
      error => {
        // error callback
        cogoToast.info('Die Anfrage konnte nicht durchgeführt werden. Bitte überprüfen Sie die Eingabefelder.');
      }
    );
  };


  return (
    <section className="py-16 px-4">
      <div className="text-center mx-auto mb-10 ">
        <span className="text-xl text-purple-600">Website &amp; Rundum-Service</span>
        <h2 className="text-3xl md:text-5xl mt-2 leading-tight tracking-wide">Fragen Sie unverbindlich und <strong>kostenlos</strong> an</h2>
      </div>
      <div className="max-w-xl lg:max-w-5xl mx-auto ">
        <form>
          <div className="text-center mb-8 md:mb-12">
            <label className="inline-block mb-4 text-xl" htmlFor="billing-monthly">
              <span>Eigene Website mit Rundum-Service ab</span><span className="py-2 px-4 ml-2 font-bold bg-purple-200 rounded-lg">49,99€ /Mo</span>
            </label>
            <br className="lg:hidden" />
          </div>
          {
          true ?
          <div className="p-8 bg-white rounded shadow-xl">
            <div className="flex flex-wrap content-center -mx-8">
              <div className="w-full lg:w-1/2 px-8">
                <h3 className="text-3xl text-center mb-8">Kostenlos anfragen</h3>
                <div id="anfragen" className="mb-6">
                  <label className="block font-bold mb-2">Name</label>
                  <input placeholder="Name"
                    type="text" onChange={(e) => setName(e.target.value)} className="appearance-none block w-full py-3 px-4 mb-2 md:mb-0 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" name="Name" autoComplete="off" style={{ backgroundImage: `url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAnVJREFUWAntVzuL4lAUPokBsVB0t9odK8VCLCal3Q4WbmUpCFv6DyymEmys9gf4B4RlS7GwEWQsBAsL195q2J3KWIiVrz0nJBcTk9yr3jCw7AG5j3PO9325j2OiAFq73X7Y7/ffsft0Op0+05wsUxTlD2K9aJr23Gw2f7txFYv8FxJ/dDtljlHICkU8ukWo9ORhk9ODEIe1yo7nUnH05JgJd3DBpcre8yD9Xly0Au9q/7aAfD4PjUYDqPWzUFegUChAIpEAav0sVAF4901eu/USEaoAL0L3nENANBqFbDYLqVTKHQe3+i6AXBOaPU4mk1Cv1yEej8PxeIR+vw/z+dx03+qzsYNatgLFYtEkp2BVVaFUKrG8W30MIKDDBATEhOpiAqbTKWw2G5OMtmA0GjHiW30MIKCjtFqtk+2ng5ZOp8EwDFiv1/a02Qb5HIGcAV7JNwwZW+8Hrw4BnFypbhRioAidbYFUdAEw/Gf8QO8H7ybA0viF1QEB0cIhmUwGqtUqxGIxM2cymcBwOLzIx1X4JF0A1YxyuWzWkgtGjwlpAiKRCFQqFdB13YPGf0qagFqtBrlczmSiOkJGFZVnKl4Hem+/26hYEfHhcIBerwe73U4IkyS+CEVygpbLJQwGA+h2u7BYLIC2RMSwFmjPeB+/4om8+8NkNpsxzqCXEBaEHZW+VFDEIyb8kLUd5wS8vnkIrc+lb7xg8p//d4jE82L4x5SHcKf/v4CrCxEe1Deq4byVX61WrBBtt1vPcMK6WgAijfFX80Q8m+x0Omcj3+746jNAdQOVG76Qgg7CIKyrBeCVfcVEHQF+0hIK8rEwyqFcwiCsv+R847qxq2vXAAAAAElFTkSuQmCC&quot;)`, backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%" }} />
                </div>
                <div className="mb-6">
                  <label className="block font-bold mb-2">E-Mail</label>
                  <input placeholder="Email"
                    type="email" onChange={(e) => setEmail(e.target.value)} className="appearance-none block w-full py-3 px-4 mb-2 md:mb-0 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none" type="email" name="email" placeholder="E-Mail" autoComplete="off" style={{ backgroundImage: `url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAnVJREFUWAntVzuL4lAUPokBsVB0t9odK8VCLCal3Q4WbmUpCFv6DyymEmys9gf4B4RlS7GwEWQsBAsL195q2J3KWIiVrz0nJBcTk9yr3jCw7AG5j3PO9325j2OiAFq73X7Y7/ffsft0Op0+05wsUxTlD2K9aJr23Gw2f7txFYv8FxJ/dDtljlHICkU8ukWo9ORhk9ODEIe1yo7nUnH05JgJd3DBpcre8yD9Xly0Au9q/7aAfD4PjUYDqPWzUFegUChAIpEAav0sVAF4901eu/USEaoAL0L3nENANBqFbDYLqVTKHQe3+i6AXBOaPU4mk1Cv1yEej8PxeIR+vw/z+dx03+qzsYNatgLFYtEkp2BVVaFUKrG8W30MIKDDBATEhOpiAqbTKWw2G5OMtmA0GjHiW30MIKCjtFqtk+2ng5ZOp8EwDFiv1/a02Qb5HIGcAV7JNwwZW+8Hrw4BnFypbhRioAidbYFUdAEw/Gf8QO8H7ybA0viF1QEB0cIhmUwGqtUqxGIxM2cymcBwOLzIx1X4JF0A1YxyuWzWkgtGjwlpAiKRCFQqFdB13YPGf0qagFqtBrlczmSiOkJGFZVnKl4Hem+/26hYEfHhcIBerwe73U4IkyS+CEVygpbLJQwGA+h2u7BYLIC2RMSwFmjPeB+/4om8+8NkNpsxzqCXEBaEHZW+VFDEIyb8kLUd5wS8vnkIrc+lb7xg8p//d4jE82L4x5SHcKf/v4CrCxEe1Deq4byVX61WrBBtt1vPcMK6WgAijfFX80Q8m+x0Omcj3+746jNAdQOVG76Qgg7CIKyrBeCVfcVEHQF+0hIK8rEwyqFcwiCsv+R847qxq2vXAAAAAElFTkSuQmCC&quot;)`, backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%" }} />
                </div>
                <div className="mb-4">
                  <button onClick={(e) => sendContactMessage(e)} className="inline-block w-full py-3 px-5 leading-none text-center text-white bg-purple-500 hover:bg-purple-600 rounded shadow">Jetzt Anfragen</button>
                </div>
              </div>
              <div className="flex lg:w-1/2 px-8 mt-8 lg:mt-0 lg:border-l">
                <div className="my-auto">
                  <div className="flex items-center text-xl mb-1"><img className="mr-2" src={starIcon} alt="" /><span>Individuelle Website</span></div>
                  <div className="flex items-center text-xl mb-1"><img className="mr-2" src={starIcon} alt="" /><span>Rundum-Service</span></div>
                  <div className="flex items-center text-xl mb-1"><img className="mr-2" src={starIcon} alt="" /><span>Google Maps Eintrag</span></div>
                  <div className="flex items-center text-xl mb-1"><img className="mr-2" src={starIcon} alt="" /><span>Google My Business Eintrag</span></div>
                  <div className="flex items-center text-xl"><img className="mr-2" src={starIcon} alt="" /><span>Logo &amp; Web Design</span></div>
                </div>
              </div>
            </div>
          </div> : ""
        }
        </form>
      </div>
    </section>
  );
}

export default cta;
